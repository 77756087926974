/* You can add global styles to this file, and also import other style files */
@import "./assets/tailwind.css";

@import '~@angular/cdk/overlay-prebuilt.css';

$color-primary-blue: #00A0FF;
$color-primary-red: #FF6483;

body {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  overflow-x: hidden;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .container {
        max-width: 1280px;
        padding: 1rem;
    }
}

img {
  max-width: 100%;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.prose.prose-red {
  a {
    color: $color-primary-red !important;
  }
}

.prose.prose-blue {
  a {
    color: $color-primary-blue !important;
  }
}

.prose {
  // Better UX/UI without flash between changing routes
  min-height: 400px !important;

  h5 {
    font-weight: bold;
    margin-bottom: -1.25rem;
  }
}

.bg-primary-blue {
  background-color: $color-primary-blue;
}

.bg-primary-red {
  background-color: $color-primary-red;
}

.hover:bg-primary-blue {
  &:hover {
    background-color: $color-primary-blue;
  }
}

.hover-text-primary-blue:hover {
  color: $color-primary-blue;
}

// By Shuffle
.bg-primary {
  background: linear-gradient(107.72deg, #00A0FF 8.88%, #00C3FF 84.36%);
}

.hover-bg-primary:hover, .hover-bg-primary:active {
  background: linear-gradient(107.72deg, #00A0FF 8.88%, #00C3FF 84.36%);
}

.text-primary {
  background: linear-gradient(107.72deg, #00A0FF 8.88%, #00C3FF 84.36%);
  color: white;
}

.text-color-primary {
  background: -webkit-linear-gradient(107.72deg, #00A0FF 8.88%, #00C3FF 84.36%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hover-text-color-primary:hover, .hover-text-color-primary:active {
  background: -webkit-linear-gradient(107.72deg, #00A0FF 8.88%, #00C3FF 84.36%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.max-w-2xs {
  max-width: 16rem;
}

.address-link {
  p {
    cursor: pointer !important;
  }
}
